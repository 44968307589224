import baseUrl from "../http/baseUrl";
import {get} from "../http/http";

//获取科技政策列表
export const getSciencePolicyList = (params) => {
  return get(`${baseUrl}/v1/policy/list`, params, {hideLoading: true})
}

//根据ID获取科技政策详情
export const getSciencePolicyDetailById = (params) => {
  return get(`${baseUrl}/v1/policy/detail`, params)
}

//查询科技政策标签列表
export const getSciencePolicyLabelList = (params) => {
  return get(`${baseUrl}/v1/policy/label`, params, {hideLoading: true});
}

//查询科技政策分类列表
export const getSciencePolicyTypeList = (params) => {
  return get(`${baseUrl}/v1/policy/second/type`, params, {hideLoading: true});
}
