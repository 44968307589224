//省市区相关接口

//获取省列表
import {get} from "../http/http";
import baseUrl from "../http/baseUrl";

//获取省列表
export const getProvinceList = (params) => {
  return get(`${baseUrl}/v1/province`, params, {hideLoading: true})
}

//根据省份code获取城市列表
export const getCityListByProvinceCode = (params) => {
  return get(`${baseUrl}/v1/cityByProvinceCode`, params, {hideLoading: true})
}

//根据城市code获取区列表
export const getAreaListByCityCode = (params) => {
  return get(`${baseUrl}/v1/areaByCityCode`, params, {hideLoading: true});
}
