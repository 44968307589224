//知识百科接口
import {get} from "../http/http";
import baseUrl, {resourceUrl} from "../http/baseUrl";

//获取知识百科分类
export const getKnowledgeType = (params) => {
  return get(`${baseUrl}/v1/article/type`, params, {hideLoading: true});
}

//获取知识百科分类下的列表
export const getKnowledgeListById = (params) => {
  return get(`${baseUrl}/v1/article/list`, params, {hideLoading: true});
}

//获取知产百科界面最新专利
export const getNewestPatentList = (params) => {
  return get(`${baseUrl}/v1/article/patent/goods`, params, {hideLoading: true});
}

//根据知产ID获取文章详情
export const getKnowledgeDetailById = (params) => {
  return get(`${baseUrl}/v1/article/detail`, params);
}

//获取知产百科界面最新成果
export const getNewestAchievementsList = (params) => {
  return get(`${resourceUrl}/v1/patent/achievement/search`, params, {hideLoading: true});
}
